<template>
  <div class="home col-12 col-lg-6 c">
    <div class="header">
      <h1>إتصال صوتي</h1>
      <h4 class="text-white">
        يمكنك الإتصال بنا من خلال المتصفح والرد عليك من خلال خدمة العملاء في
        اسرع وقت.
      </h4>
    </div>
    <!-----start-main---->
    <!---728x90--->
    <div class="login-form">
      <div class="col-12 col-lg-8 c">
        <br />
        <div class="col-12 text-center" v-if="loading">
          <img
            :src="require('@/assets/loading.svg')"
            style="width: 100px"
            alt=""
          />
        </div>
        <div v-if="!call.number && !loading">
          <button class="btn btn-lg btn-success btn-block" @click="callNow()">
            إتصال الآن
          </button>
        </div>
        <div v-if="call.number && !loading">
          <img
            :src="require('@/assets/phone.gif')"
            v-if="!notime"
            style="width: 250px; max-width: 60%"
            alt=""
          />
          <br />
          <span
            v-if="!notime"
            :class="timer == '00:00' ? 'text-dark' : 'text-danger'"
          >
            <i class="fa fa-clock-o"></i>
            {{ timer }}</span
          >
          <br /><br />
          <div class="col-12 col-lg-8 c" id="buttons">
            <div class="row">
              <div class="col-6 c">
                <button
                  class="btn btn-lg btn-outline-success text-dark"
                  v-if="!muted"
                  @click="mute()"
                >
                  <i class="fa fa-microphone"></i>
                  كتم
                </button>
                <button
                  class="btn btn-lg btn-outline-success text-danger"
                  v-if="muted"
                  @click="mute()"
                >
                  <i class="fa fa-microphone-slash"></i>
                  كتم
                </button>
              </div>
              <div class="col-6 c">
                <button
                  class="btn btn-lg btn-outline-danger text-dark"
                  @click="endCall()"
                >
                  <i class="fa fa-times"></i>
                  انهاء
                </button>
              </div>
            </div>
          </div>
          <span id="number" class="g"></span>

          <div class="col-12 col-lg-8 c" v-if="ended && to_socket">
            <br />
            <div class="row">
              <div class="col-12">
                <p>ما تقييمك للمكالمة او للدعم الفني؟</p>
              </div>
              <div class="col-6 goodarea">
                <button class="btn btn-lg btn-success good" @click="good()">
                  <i class="fa fa-thumbs-up"></i>
                  <br />
                  جيد
                </button>
              </div>
              <div class="col-6 badarea">
                <button
                  class="btn btn-lg btn-outline-danger bad"
                  @click="bad()"
                >
                  <i class="fa fa-thumbs-down"></i>
                  <br />
                  سيئ
                </button>
              </div>
              <div class="col-12 g">
                <div class="form-group">
                  <label for="">هل هناك ملاحظات او شكوى تود اضافتها؟</label>
                  <textarea
                    class="form-control"
                    name=""
                    id="notes"
                    placeholder="اكتب هنا.. (اختياري)"
                    rows="3"
                  ></textarea>
                </div>
                <div class="col-12 text-center notesarea">
                  <br />
                  <button class="btn btn-sm btn-primary" @click="sendNotes()">
                    إرسال الملاحظات <i class="fa fa-arrow-left"></i>
                  </button>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <br />
        <a href="#" @click="endCall()" class="text-dark">
          <i class="fa fa-reply"></i>
          العودة للصفحة الرئيسية</a
        >
        <br />
      </div>
    </div>
    <!---728x90--->
    <!--//End-login-form-->
    <!-----start-copyright---->
    <div class="copy-right">
      <!---728x90--->
      <p>جميع الحقوق محفوظة لدى شركة برمجة دوت كوم</p>
    </div>
    <!-----//end-copyright---->
  </div>
</template>

<script>
import $ from "jquery";
import { Peer } from "peerjs";

export default {
  data() {
    return {
      api: window.api,
      userdata: window.userdata,
      call: {},
      loading: false,
      timer: "00:00",
      audio: null,
      notime: false,
      muted: false,
      ok: false,
      to_socket: null,
      ended: false,
      mediaStream: null,
    };
  },
  beforeRouteLeave() {
    this.sockets.unsubscribe("income-voice");
    this.sockets.unsubscribe("income-sound");
    if (this.audio) {
      this.audio.pause();
    }
  },
  methods: {
    good() {
      $.post(this.api + "/client/call/good", {
        data: this.userdata,
        id: this.call._id,
      }).then(function () {
        $(".goodarea").html("تم الإرسال");
        $(".badarea").html(" ");
      });
    },
    bad() {
      $.post(this.api + "/client/call/bad", {
        data: this.userdata,
        id: this.call._id,
      }).then(function () {
        $(".badarea").html("تم الإرسال");
        $(".goodarea").html(" ");
      });
    },
    endCall() {
      $.post(this.api + "/client/call/end", {
        data: this.userdata,
        id: this.call._id,
      })
        .then(function () {
          window.location = "/";
        })
        .catch(function () {
          window.location = "/";
        });
      setTimeout(() => {
        window.location = "/";
      }, 1000);
    },
    sendNotes() {
      $.post(this.api + "/client/call/notes", {
        data: this.userdata,
        id: this.call._id,
        notes: $("#notes").val(),
      }).then(function () {
        $(".notesarea").html("تم الارسال، شكراً لك");
      });
    },
    mute() {
      this.muted = !this.muted;
      this.mediaStream.getAudioTracks()[0].enabled = !this.muted;
    },
    callNow() {
      var g = this;
      g.sockets.subscribe("call-ended", function (data) {
        g.sockets.unsubscribe("income-voice");
        g.sockets.unsubscribe("income-sound");
        g.audio.pause();
        $("#buttons").html(
          `<Strong class='text-danger'>انتهت المكالمة</Strong><br><small class='text-muted'>"اذا انتهت المكالمة فجأة، فربما حصل مشكلة في الاتصال بينك وبين الدعم الفني"</small>`
        );
        g.ended = true;
        g.notime = true;
      });
      g.sockets.subscribe("no_available_admins", function () {
        setTimeout(() => {
          $("#buttons").html(`
        <button class="btn btn-lg btn-secondary acx__asd btn-block">
          <i class="fa fa-comment"></i>
          المحادثة بالرسائل
        </button>
        <br /><br />`);
          $(".acx__asd").click(function () {
            g.$router.push("/chat");
          });
        }, 1000);
      });
      g.sockets.subscribe("income-sound", function (data) {
        g.audio = new Audio(require("@/assets/" + data + ".mp3"));
        g.audio.play();
      });
      g.sockets.subscribe("call-rejected", function (data) {
        g.audio.pause();
        setTimeout(() => {
          $("#buttons").html(`الخط مشغول <br><br>
          `);
          g.notime = true;
          $(".refresh").click(function () {
            location.reload();
          });
        }, 1000);
      });
      g.sockets.subscribe("call-accepted", function (data) {
        g.audio.pause();
        g.audio = new Audio(require("@/assets/call-accepted.mp3"));
        g.audio.play();
        $("#number").html("<br>رقم المكالمة: " + g.call.number);
        g.ok = true;
        g.to_socket = data.socket;
        setTimeout(() => {
          g.audio.pause();
        }, 1000);
        //
        const peer = new Peer();
        peer.on("open", function (id) {
          var conn = peer.connect(data.peer_id);
          setTimeout(() => {
            // handle browser prefixes
            navigator.getUserMedia =
              navigator.getUserMedia ||
              navigator.webkitGetUserMedia ||
              navigator.mozGetUserMedia ||
              navigator.msGetUserMedia;

            // Get access to microphone
            navigator.getUserMedia(
              // Only request audio
              { video: false, audio: true },

              // Success callback
              function success(localAudioStream) {
                // Do something with audio stream
                g.mediaStream = localAudioStream;
                localAudioStream = g.mediaStream;
                var call = peer.call(data.peer_id, localAudioStream);
                call.on("stream", (remoteStream) => {
                  var audio = $("<video autoplay playsInline />").appendTo(
                    "body"
                  );
                  audio[0].srcObject = remoteStream;

                  if (audio[0].paused) {
                    audio[0].play();
                  }
                });
              },
              // Failure callback
              function error(err) {
                console.log(err);
                // handle error
              }
            );
          }, 500);
        });
        peer.on("open", function (id) {
          console.log("My peer ID is: " + id);
        });
        //
        setTimeout(() => {
          var msec = 0;
          var sec = 0;
          var min = 0;
          var timer = setInterval(clock, 1000);
          function clock() {
            msec += 1;
            if (msec == 60) {
              sec += 1;
              msec = 0;
              if (sec == 60) {
                sec = 0;
                min += 1;
              }
            }
            g.timer = min + ":" + sec + ":" + msec;
          }
        }, 0);
      });
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          g.loading = true;
          $.post(g.api + "/client/call/make", {
            data: g.userdata,
          })
            .then(function (response) {
              response = JSON.parse(response);
              g.loading = false;
              if (response.status == 100) {
                g.call = response.response;
                //

                //
              } else {
                alert(response.response);
              }
            })
            .catch(function () {
              g.loading = false;
              alert("برجاء اعادة المحاولة");
            });
        })
        .catch((err) => {
          alert("برجاء الموافقة على استعمال المايكروفون");
        });
    },
  },
  created() {},
};
</script>
